<template>
  <div
    class="base-spinner"
    :class="cssClasses"
    :style="cssStyles"
  ></div>
</template>

<script>
import './BaseSpinner.scss';

export default {
  name: 'BaseSpinner',
  props: {
    size: {
      type: Number,
      default: 16,
    },
    variant: {
      type: String,
      default: 'white',
      validator: (value) => ['white', 'primary'].includes(value),
    },
    center: {
      type: Boolean,
    },
  },
  computed: {
    cssClasses() {
      return [
        `-variant-${this.variant}`,
        this.center ? '-center' : null,
      ];
    },
    cssStyles() {
      return {
        fontSize: `${this.size}px`,
      };
    },
  },
};
</script>
