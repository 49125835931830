<template>
  <div
    class="info-box"
    :class="cssClasses"
  >
    <FontAwesomeIcon
      v-if="icon"
      class="icon"
      :icon="icon"
    />
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import './InfoBox.scss';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'InfoBox',
  components: {
    FontAwesomeIcon,
  },
  props: {
    icon: {
      type: Object,
    },
    variant: {
      type: String,
      default: 'info',
      validator: (value) => ['info', 'success', 'danger'].includes(value),
    },
    size: {
      type: String,
      default: 'large',
      validator: (value) => ['large', 'small'].includes(value),
    },
  },
  computed: {
    cssClasses() {
      return [
        `-variant-${this.variant}`,
        `-size-${this.size}`,
      ];
    },
  },
};
</script>
