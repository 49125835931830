<template>
  <section class="section section-leadership">
    <div class="container">
      <BaseHeading>
        Vedenie spoločnosti
      </BaseHeading>
      <div class="row">
        <div
          v-for="(item, i) in team"
          :key="i"
          class="col-md-3"
        >
          <PersonCard
            :image="item.image"
            :name="item.name"
            :role="item.role"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable global-require */
import BaseHeading from '@/components/BaseHeading.vue';
import PersonCard from '@/components/PersonCard.vue';

export default {
  props: {
    heading: String,
  },
  components: {
    BaseHeading,
    PersonCard,
  },
  data: () => ({
    team: [
      {
        image: require('@/assets/leadership/marendiak.jpg'),
        name: 'Michal Marendiak',
        role: 'Predseda predstavenstva',
      },
      {
        image: require('@/assets/leadership/dobias.jpg'),
        name: 'Martin Dobiaš',
        role: 'Podpredseda predstavenstva',
      },
      {
        image: require('@/assets/leadership/strivinsky.jpg'),
        name: 'Eduard Strivinský',
        role: 'Člen predstavenstva',
      },
      {
        image: require('@/assets/leadership/foltanova.jpg'),
        name: 'Ľubica Foltánová',
        role: 'Člen predstavenstva',
      },
    ],
  }),
};
</script>
