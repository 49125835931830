<template>
  <div class="product-card">
    <div class="iconwrap">
      <img
        class="icon"
        :src="icon"
        :alt="title"
      >
    </div>
    <div class="titlewrap">
      <h3 class="title">
        {{ title }}
      </h3>
    </div>
    <div class="actions">
      <BaseButton
        v-if="buttonMore"
        variant="secondary"
        size="small"
        @click="openURL(buttonMore)"
      >
        Zistiť viac
      </BaseButton>
      <BaseButton
        v-if="buttonCalculator"
        variant="primary"
        size="small"
        @click="openURL(buttonCalculator)"
      >
        Kalkulačka
      </BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from '@gds/components/BaseButton';

export default {
  props: {
    icon: String,
    title: String,
    buttonMore: String,
    buttonCalculator: String,
  },
  components: {
    BaseButton,
  },
  methods: {
    openURL(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.product-card {
  flex: 0 0 100%;
  border: 1px solid $gray-300;
  text-align: center;
  padding: $spacer-1;
  margin-bottom: -1px;

  display: flex;
  flex-direction: column;
  .iconwrap {
    flex: 0 0 144px;
    height: 144px;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      display: block;
      max-width: 80px;
      max-height: 80px;
      width: auto;
      height: auto;
    }
  }
  .titlewrap {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title {
    font-size: $font-size-400;
    font-weight: 700;
  }
  @media screen and (min-width: 768px) {
    flex: 0 0 25%;
    max-width: 25%;
    margin-left: -1px;
    .actions {
      * + * {
        margin: 4px;
      }
    }
  }
}
</style>
