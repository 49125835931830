<template>
  <div class="tooltip-set">
    <div
      class="origin"
      ref="origin"
      :aria-describedby="autoId"
    >
      <slot name="origin">
        <InterfaceIcon
          element="button"
          icon="info"
          :size="buttonSize"
          block
        />
      </slot>
    </div>
    <TooltipWindow
      ref="tooltip"
      :id="autoId"
      :placement="tooltipPlacement"
      style="display: none"
    >
      <slot></slot>
    </TooltipWindow>
  </div>
</template>

<script>
import './TooltipSet.scss';
import InterfaceIcon from '@gds/components/InterfaceIcon';
import TooltipWindow from '@gds/components/TooltipWindow';

import { createPopper } from '@popperjs/core';

export default {
  name: 'TooltipSet',
  components: {
    InterfaceIcon,
    TooltipWindow,
  },
  props: {
    buttonSize: {
      type: String,
      default: 'medium',
    },
  },
  data() {
    return {
      popperInstance: null,
      tooltipPlacement: null,
    };
  },
  computed: {
    autoId() {
      const id = Math.round(Math.random() * 1000);
      return `tooltip${id}`;
    },
  },
  methods: {
    watchTrigger() {
      ['mouseenter', 'focus'].forEach((event) => {
        this.origin.addEventListener(event, this.create);
      });
      ['mouseleave', 'blur'].forEach((event) => {
        this.origin.addEventListener(event, this.destroy);
      });
    },
    async create() {
      const wide = window.innerWidth > 960;
      this.tooltip.style.display = 'block';
      this.popperInstance = await createPopper(this.origin, this.tooltip, {
        placement: wide ? 'right' : 'auto',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 13],
            },
          },
        ],
      });
      this.tooltipPlacement = this.popperInstance.state.placement;
    },
    destroy() {
      this.tooltip.style.display = 'none';
      if (this.popperInstance) {
        this.popperInstance.destroy();
        this.popperInstance = null;
      }
    },
    watchScroll() {
      window.addEventListener('scroll', this.destroy);
    },
    watchScrollEnd() {
      window.removeEventListener('scroll', this.destroy);
    },

  },
  mounted() {
    this.origin = this.$refs.origin;
    this.tooltip = this.$refs.tooltip.$el;
    this.watchTrigger();
    this.watchScroll();
  },
  beforeDestroy() {
    this.watchScrollEnd();
  },
};
</script>
