<template>
  <div class="feature-item">
    <div class="header">
      <img class="title-image" v-if="titleImage" :src="titleImage" :alt="title">
      <h2 v-else class="title" v-html="title"></h2>
    </div>
    <h3 class="subtitle">{{ subtitle }}</h3>
    <p class="text">{{ text }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    titleImage: String,
    subtitle: String,
    text: String,
  },
};
</script>

<style lang="scss" scoped>
.feature-item {
  margin-bottom: $spacer-2;
  .header {
    margin-bottom: $spacer-1;
  }
  .title {
    font-size: 36px;
    font-weight: 700;
    color: $red-40;
    line-height: 0.9;
  }
  .title-image {
    display: block;
    width: 100%;
    height: auto;
  }
  .subtitle {
    font-size: $font-size-300;
    min-height: 60px;
  }
  .text {
    font-size: $font-size-200;
  }
  @media screen and (min-width: 992px) {
    margin-bottom: 0;
    .header {
      min-height: 120px;
    }
  }
}
</style>
