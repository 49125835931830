<template>
  <div class="person-card">
    <img
      :src="image"
      :alt="name"
      class="image"
    >
    <div class="text">
      <h3 class="name">{{ name }}</h3>
      <p class="role">{{ role }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: String,
    name: String,
    role: String,
  },
};
</script>

<style lang="scss">
.person-card {
  box-shadow: 0px 9px 44px rgba(0, 0, 0, 0.11);
  text-align: center;
  margin-bottom: $spacer-1;
  .text {
    padding: $spacer-1;
  }
  .image {
    width: 100%;
    height: auto;
    display: block;
  }
  .name {
    text-transform: uppercase;
    font-size: $font-size-200;
    letter-spacing: 0.05em;
  }
  .role {
    font-size: $font-size-200;
    color: $gray-600;
    font-weight: 700;
  }
}
</style>
