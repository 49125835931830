<template>
  <section class="section section-links">
    <div class="container">
      <BaseHeading>
        Ďalšie povinne zverejňované informácie
      </BaseHeading>
      <div class="row">
        <div
          v-for="(item, i) in items"
          :key="i"
          class="col-md-3"
        >
          <a
            :href="item.link"
            class="link-box"
            target="_blank"
          >
            <img class="icon" src="@/assets/icons/document.svg" alt="">
            <span v-html="item.label"></span>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BaseHeading from '@/components/BaseHeading.vue';

export default {
  components: {
    BaseHeading,
  },
  data: () => ({
    items: [
      {
        label: 'Udržateľné<br> financie',
        link: './docs/udrzatelne-financie.pdf',
      },
      {
        label: 'Kódex správania Skupiny Generali',
        link: './docs/kodex-spravania-2024.pdf',
      },
      {
        label: 'Nezohľadňovanie nepriaznivých vplyvov poistného a investičného poradenstva na faktory udržateľnosti',
        link: './docs/nezohladnovanie-nepriaznivych-vplyvov.pdf',
      },
      {
        label: 'Oznamovanie protispoločenskej činnosti (whistleblowing)',
        link: './docs/whistleblowing3.pdf',
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.link-box {
  color: $gray-900;
  text-decoration: none;
  font-weight: 700;
  display: flex;
  .icon {
    width: $spacer-2;
    height: $spacer-2;
    margin-top: 0.5rem;
    margin-right: $spacer-1;
  }
}
</style>
