<template>
  <div class="home">
    <SectionHero/>
    <SectionFeatures id="o-nas"/>
    <SectionContact id="kontakt"/>
    <SectionProductsInsurance id="produkty"/>
    <SectionProductsBanking/>
    <SectionPartners/>
    <SectionCareer id="kariera"/>
    <SectionLeadership/>
    <SectionCompanyInfo/>
    <SectionPrivacy id="osobne-udaje"/>
    <SectionLinks/>
  </div>
</template>

<script>
import SectionHero from '@/components/SectionHero.vue';
import SectionFeatures from '@/components/SectionFeatures.vue';
import SectionProductsInsurance from '@/components/SectionProductsInsurance.vue';
import SectionProductsBanking from '@/components/SectionProductsBanking.vue';
import SectionCareer from '@/components/SectionCareer.vue';
import SectionCompanyInfo from '@/components/SectionCompanyInfo.vue';
import SectionPrivacy from '@/components/SectionPrivacy.vue';
import SectionLinks from '@/components/SectionLinks.vue';
import SectionPartners from '@/components/SectionPartners.vue';
import SectionLeadership from '@/components/SectionLeadership.vue';
import SectionContact from '@/components/SectionContact.vue';

export default {
  name: 'Home',
  components: {
    SectionHero,
    SectionFeatures,
    SectionProductsInsurance,
    SectionProductsBanking,
    SectionCareer,
    SectionCompanyInfo,
    SectionPrivacy,
    SectionLinks,
    SectionPartners,
    SectionLeadership,
    SectionContact,
  },
};
</script>
