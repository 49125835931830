<template>
  <div
    class="brand-badge"
    :class="{ '-negative': negative }"
  >
    <img v-if="negative" src="@/assets/generali-logo-negative.svg" alt="Generali Logo" class="logo">
    <img v-else src="@/assets/generali-logo.svg" alt="Generali Logo" class="logo">
    <div class="text">Generali Slovenská distribúcia</div>
  </div>
</template>

<script>
export default {
  props: {
    negative: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.brand-badge {
  display: inline-flex;
  .text {
    font-size: $font-size-200;
    padding-left: 12px;
    margin-left: 12px;
    text-align: left;
    max-width: 88px;
    border-left: 1px solid $gray-400;
    color: $red-10;
  }
  &.-negative {
    .text {
      border-left-color: rgba(white, 0.5);
      color: $white;
    }
  }
}
</style>
