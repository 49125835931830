import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/podnety',
    name: 'Suggestions',
    component: () => import(/* webpackChunkName: "suggestions" */ '../views/Suggestions.vue'),
  },
  {
    path: '/osobne-udaje',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue'),
  },
];

const router = new VueRouter({
  // mode: 'history',
  routes,
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

export default router;
