<template>
  <input
    class="input-text"
    :class="cssClasses"
    :type="type"
    :value="value"
    :name="name"
    :autocomplete="autocomplete"
    :inputmode="inputmodeUsed"
    :autocapitalize="autocapitalize"
    @input="$emit('input', $event.target.value)"
    @change="$emit('change', $event.target.value)"
    @blur="$emit('blur', $event)"
  >
</template>

<script>
import './InputText.scss';

export default {
  name: 'InputText',
  props: {
    value: {
      type: [String, Number],
    },
    type: {
      type: String,
      default: 'text',
      validator: (value) => {
        const allowed = ['text', 'email', 'number', 'tel', 'password', 'search', 'url', 'date'];
        return allowed.includes(value);
      },
    },
    align: {
      type: String,
      default: 'left',
      validator: (value) => ['left', 'center', 'right'].includes(value),
    },
    name: {
      type: String,
    },
    inputmode: {
      type: String,
      validator: (value) => ['text', 'numeric'].includes(value),
    },
    autocomplete: {
      type: String,
      default: 'on',
    },
    autocapitalize: {
      type: String,
      default: null,
    },
  },
  computed: {
    cssClasses() {
      return [
        `-type-${this.type}`,
        `-align-${this.align}`,
      ];
    },
    inputmodeUsed() {
      if (this.inputmode) {
        return this.inputmode;
      }
      if (['email', 'tel', 'url'].includes(this.type)) {
        return this.type;
      }
      return 'text';
    },
  },
};
</script>
