const ajaxSend = (method, url, data, handleSuccess, handleError) => {
  const xhr = new XMLHttpRequest();
  xhr.open(method, url);
  xhr.setRequestHeader('Accept', 'application/json');
  xhr.onreadystatechange = () => {
    if (xhr.readyState !== XMLHttpRequest.DONE) return;
    if (xhr.status === 200) {
      handleSuccess(xhr.response, xhr.responseType);
    } else {
      handleError(xhr.status, xhr.response, xhr.responseType);
    }
  };
  xhr.send(data);
};

export default ajaxSend;
