<template>
  <svg class="widget-map" viewBox="0 0 500 246" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2">
    <path d="M182 496c2-3 2-7 2-10v-3-2l1 1 2 1c1 1 2 0 3-1l4-5 1-3v-3l6-4 5-4 3-6v-3l-1-1v-1c1-1 3-2 3-4l-1-3-1-2v-10l1-5-1-5-1-2-1-1-1-2-1-1c0-2-1-3-4-3l-2 1h-1v-3c1-1 1-2-1-5a6 6 0 0 0-2-3v-1l-1-1-2-2h3l4-2c1-2 0-4-1-5l-1-1h8c2-1 3-2 4-5v-5l-2-2-1-2-1-1v-2l1-4v-2-2l-2-2-1-1 1-4 2-5 3-8 4-6 3-5 5-7 2-3v-4l-6-5-2-1-2-1c-3-3-3-4-7-5l1-2v-2-2-2-1l3-2 1-1 1-1 3 2h3c2 0 4-2 4-4l2-3 1-2c3-1 5-3 6-6 1-2 1-6-1-10l-2-4v-4-1l1 1 2 1 11 3h2l1 2a60 60 0 0 0 5 8c1 2 6 4 10 6a29 29 0 0 1 4 3l-1 1-2 2c-2 2-2 4-2 5l1 1v3l1 2 3 1h5l1 2 1 3c1 3 2 6 5 9l2 1 2-1a10 10 0 0 0 2-2l1 1 2 2c2 0 3-1 3-2l1-1v-1l10-9a13 13 0 0 1 3-2h1a26 26 0 0 0 0 1l1 3h-1l-1 3-1 2v2l1 1h2l4-2h1l1 2 1 5-2 2v3l1 1v5l2 3c3 2 4 3 5 7v4l-1 1-2 2-4 5v5l4 1 3-2 2-3 3-2h3l4 1c2 0 5 0 6-2 2-2 2-3 2-5v-3c1-2 2-5 5-7l3-1 7 10 2 2 2 2 2 4 1 4 2 2h1v1a15 15 0 0 1-1 6h-1l-2 1-2 3 1 9v4l-1 2-1 1c-2 1-3 3-3 5v3l1 1h2l2-1 2-1 3 1 3 2c4 2 7 3 12 1l3-3 3-3 3-1h5l3-2 1-1h1v1l-2 5 1 4c1 2 2 3 1 5l-1 1-4 3c-3 2-4 4-4 6l1 5 1 2-1 1c-2 0-3-1-3-2h-2l-2-3h-1l-1 1h-3l-8 6c-3 0-7 0-9 3l2 2-1 2v7h-1c-1 2-4 1-5 3 0 4-2 21-1 25h1l2 4 4 2c0 5 2 4 5 5 1 1 2 4 1 6l-7-2h-10l-3 2-1 6c-4 1-6 4-9 7-6 1-5 1-11-1l-7-2-2 1-3 1c-2 1-4 3-7 2l-3-2-5-1-1 1-4 3-10 2-9 2-4-1-5-1-8 1-4-2c-4-1-9-3-14-3l-9 1c-1 1-1 2-4 1l-6-2-3 4c-3 0-4-1-6 1l-6-2-4 1h-5-4l-3-2-7-1z" fill="currentColor" transform="translate(-22 -26) scale(.54274)" id="nitriansky" @click="openStores"/>
    <path d="M57 306l1-1-1-3-2-4v-7l2-2v-2l-2-1 4-9 1-5 1-3c1-2 4-3 4-5 1-7 3-13 8-17l5-5c3-3 4-6 8-8l7-5 6-1h3l3 1c6 3 14 10 21 10l3-1-1 4-4 2-3 3-1 3 3 2h5l3-3 1-1 1 1 1 3 2 2h1l4 5v1l-2 2v3c0 4 1 6 2 7l3 5 2 5 2 4 3 1 2-1 2-2h7l2-1 2-2 1-3v-2h6l4-1 3-1a3 3 0 0 1 1 1l1 2 5 5 1 1h4c3 1 4 0 5-1a9 9 0 0 0 1-1l3 2 4 2 5 1 3 2 4 2h4v1l-1 1-1 2-1 1h-2l-3-2c-3 0-4 1-6 4l-2 1c-1 1-3 2-2 4v5l-1 2v2l2 2 6 4c1 2 2 2 3 2l2 1 3 2 2 2-2 3-5 6-3 6-4 6-3 8-3 5a9 9 0 0 0-1 7l1 2 2 2v2l-1 4v4l2 3h2v4l-2 3h-2l-2-1-3-1-2 1-1 3 1 3 1 1v1h-6c-2 0-3 1-3 3l1 1 1 2a854 854 0 0 0 5 5l1 2v9l2 1h3l2-1h1v2a7 7 0 0 0 3 3l1 6-1 5v10l1 2 1 1-1 2-1 1-2 2 1 2 1 2-1 3-1 2-5 3c-3 1-5 3-6 5l-1 5-1 2a11 11 0 0 1-2 3l-2-2h-3l-2 2-1 6-1 7v-1l-8-6-7-5-5 1-8-9-3-4-6-5v-1l-4-7-6-6-7-8h-4l-2-1-3-2-6 1-3-7 10-10 5-5 1-3v-2l-1-1-4-1-1-1v-1h5l6-1 3-2v-2-3l-1-4v1l3 4 3 1 3-1 4-4 5-2a26 26 0 0 0 4-5v-2l-2-2-1-1h1l1-2-1-3-2-1h-4v-1l1-1 4-5 2-2v-2l-4-3-6-2v-5l3-6v-3-1l-2-1-1-1-3-2h-2a4 4 0 0 1 0-1l-1-1v-2l-1-2-4-6h-1v-1l-1-2-2-1h-2l-2-2h-3l-1-1-1-2v-2l2-1 3-5v-2l1-1h-1l-1-2-3-2-3-3 1-1c3-2 4-4 5-7l4-1 1-1 1-2 1-1v-6c-1-3-3-6-5-7h-3l-2 3-5 6v1h-1l-2 1-4 2-3 4-3 3-4 1-5 2-2 1h-1v-1l-1-1-4-2h-6l-9 2-2 1c-4 3-5 4-9 4z" fill="currentColor" transform="translate(-22 -26) scale(.54274)" id="trnavsky" @click="openStores"/>
    <path d="M55 310c5 0 9-1 13-4l2-1 8-2h4l2 1 3 3 5-1 5-2 5-1 4-4 3-4 1-1h2l3-1 2-3a48 48 0 0 1 5-7 8 8 0 0 1 3 6l-1 2-1 1-4 1-2 2c-1 3-1 4-4 6l-2 3c0 1 0 3 2 4a14 14 0 0 1 2 3l3 1v2l-1 2-2 1c-2 2-3 4-2 7l1 3 4 2 3 1 1 1 3 1v1h1v2l4 5 1 1 2 5 3 1 1 1h1l2 2v1l-3 6v6l1 3 1 1 5 1a10 10 0 0 1 3 1l-1 2-5 4-1 3v2l3 2 3 1-1 2 1 3 1 1-1 2-5 3-5 4-5-6c-2-1-3-2-5 0l-1 3 2 5v3h-1l-4 1h-6l-2 2-1 3 1 3 2 1 3 1-1 1-4 4-9 10-2 1v1h-7l-5 2-3-3-4-2c-1 1-4 2-5 1v-2l1-4-1-1v-4l2-6v-1l-3-1c-1-3-2-8-4-9l-3-2-7-5-1-5-2-6-2-6v-6l-1-2h-4c0-1-1-4-3-5l-1-3 2-2v-2l-1-2c-3 0-4-2-5-5h-4l-1-7 2-2 1-5-2-8 2-2-1-1 1-1 3-4h2v-4l3-1 1-4 2-1z" fill="currentColor" transform="translate(-22 -26) scale(.54274)" id="bratislavsky" @click="openStores"/>
    <path d="M393 418l-1-1-1-4 1-1 2-3 4-2 2-3v-7l-2-2v-1-1l1-2c1-2 2-3 1-4l-1-2-3-1-3 1-2 1-1 1h-5l-4 1-4 4-2 3c-4 1-7 0-9-2-3-2-5-3-8-3l-2 1 1-1 2-1 1-3 1-7-1-8h1l3-2 1-2 1-8-2-2-1-1-1-1-1-2c0-2 0-4-3-6l-1-1-2-3-7-10c0-1-1-2-3-1l-6 2-6 9v4l-1 3h-3l-8-1-2 2 2-4v-5c-1-6-2-6-6-10l-1-1v-1-1-4h-1l1-1v-3-6l-2-4c-2-2-3-1-5-1l1-3-1-2 1-2c1-2 2-2 4-2h9l3-1 1-2v-1-1l2-2 3-4 1-3 2-2h3l3 2h5c2-2 3-5 4-9l1-4v-1l3-2 4-2 1-2 1-3 2-2 2-3 1-3 1-1 3-2 1-2 1 1 1 1 3 5 6 1 7-3h2l3-1 1-1v-3c1-5-1-6-3-10v-1l2-4v-2l-2-1 1-1 6-7 2 1h9l8-2 4-1 4-2 4 1v3l1 3 2 2 6-1c3 0 5-1 5-2l2-2c0-2 0-3 2-5l4-2 4-3h1l2-1h5l3-1 3-1 12-2h10l1 1 2 2 3 2h5l5 4 3 2 3-1 7-4 3 1 6 2a768 768 0 0 1 20-3v1l4 1 1 1 8 2h5l10 1 3-2v-1l2 1 4 4 4 2-2 1v7l-4 1-3 3v12l-2 3c-1 2-2 5 0 8 1 2 3 3 6 3l4 1 6 6 2 5 1 2v5l1 6 5 3h3l-1 1-4 9c-2 4-3 7-3 11 0 2 0 5 2 7l2 2 2 2 2 1h2l3-1 2-1 1 2 3 1h1l1-1 1-2h5v3c-2 0-7 5-8 8v5l-2 4v5l-4 4-6 11c-2 2-2 5-5 6l-2 1-8-4h-1l-1 5h-6v2l-1 1c-3-1-6-4-8-3v9l-3 3-4 3-5 3v1l2 2-5 3-4-3c-1 2-3 2-5 3l-3 2c-4 3-6 7-11 8l-1-1 2-4c-1-3-4-5-7-6h-2l-1-1h-1v1l-3 1-3 2h-2l-1-3 2-4-1-2c-2-1-3-4-6-5-3 0-5 4-9 1l-2-2v-4l-9-2-5 6-3 1h-7l-1 2-3 8v6l-3 3v5c-1 2 0 5-3 7h-1l-1-1-1-3c-1 2-7 4-9 5l-2-2c-1 2-10 0-13 2l-1 1h-2l-1 1h-3l-3 5-3-1h-4l-6-1-4-2-2 1h-6l-3 2h-2c-1-1-3-3-5-3z" fill="currentColor" transform="translate(-22 -26) scale(.54274)" id="banskobystricky" @click="openStores"/>
    <path d="M627 320l-4-1h-4l-3 2c-2-2-3-3-5-2l-1 1h-1l-2 1h-1-1l-2-2-1-2-1-5 3-9 4-10 1-3-1-2h-5l-2-1-1-4v-4l-1-2-1-2-1-6c-2-2-6-7-9-8h-4l-4-2c-1-2 0-4 1-6l2-4v-3-4-2l1-1 1-1 3-1 2-3v-5l1-1a7 7 0 0 0 2-1c3-1 4-2 5-4l1-4h1l1-1 5-3 1-2-1-3-4-2-1-1v-1c2-1 5-1 7-3l9-5 2-2h2a187 187 0 0 0 4 6l2 1 3-2 1-3v-2c2 0 4 1 5 3l2 2 3 1h5v1l1 2 2 4 4 1 5-3 4-5 1-1 1-2 1 1v1c1 2 3 3 5 3h3l3-1 1-2v-2-2h1v1l1 1 4 1c0 2 1 4 3 4h7l2 2 6 2 3-1h3l1 1 1 3 2 1v2c0 1 1 3 6 7h1l4-1 3 2 3 1 4-1a618 618 0 0 0 4-1l1 3 1 2 2 1h3l2-3 1 1 1 1h3v1l-2 2-1 3 2 5 2 2 2 1h7c2-1 2-2 2-3v-1-1h6l4-1 2-4v-6-1l3 2a2 2 0 0 0 3-1v-1 1l1 1h4l2-1h1l2 8 2 2 2 2v3l2 4c1 2 4 2 5 2l4 2 2 2h9l3 2 5 2 6-1 6-5h5l3-3c1-3 0-4-1-5l-1-3v-4-5l-2-4-1-2 1-1h1l1-1 12 4 6 3 2 3c1 3 4 4 7 5h15l3-1 2-1h3l3-1 1-3v-7l2-1h1l2-1 3-3 1-2 2-2 2-1 2 1v2 9l1 3 2 1 2 1 5-2 13 1c-2 4-1 12-1 16-2 3-6 7-5 11l2 1c1 4-2 8-2 12l-14 9-2 1-4 7c-3 2-8 6-9 9l-1 5c-1 3-1 7 1 11-2 3-2 9-2 12 0 4-2 4 0 9l-1 7c-2 1-5 4-5 6l-6 1c0-2-2-3-4-3l-1 1h-1l-2-1-5 1h-1l-2 1-1-1-4 1c-3 2-10 5-13 5l-3-1h-1l-2 4-2 3-2-2h-2v-1h-1l-1 1h-1l-9-2-1-3-3-1 1-2-5-5-2-8-3-4c-2-3-3-11-4-15l-3-1-3 2-6-2c-1-2-4-6-4-9l-2-2h-2c-1-2-8-6-10-6l-2 5-1 1-1-1-7 1h-5l-3 1c0 3-2 4-1 7-5 1-8-1-12-3h-3l-1 3-8 1c-2 1-4 4-4 6l-3 1-3-1v-4l-4-2h-3l-6-2-3 1-2 2-4-1h-1v-2l-2-4h-1v-1l-2-2h-2l-5 2-5-4-2-4c-4 2-11 3-15 3l-7 1-2 1h-6l-1 2h-3l-3 3h-2l-2 1-5-2-3 3-3-1c-1-1-3 0-4 1l2 3 1 4-4 7z" fill="currentColor" transform="translate(-22 -26) scale(.54274)" id="kosicky" @click="openStores"/>
    <path d="M324 207l2-1 2-1h1l2 1 2 1h7l1 2a82 82 0 0 0-1 4l-1 5 1 2v2l-2 3 1 6 1 3 2 1h2l6 5 1 4 1 5 4 3 4 2v1l-2 2-1 2-3 4-1 2v2l-3 1-4 2-2 6-1 6-1 2-1-1-2-1c-2-1-6-3-9 0l-2 3-1 3-3 3-2 3-1 2v1h-10c-2 0-5 1-6 4l-3-1-4 2-11 9-1 2-1 1-4-2-2 2h-1c-2-2-2-5-3-7-1-3-2-5-4-6l-5-1h-2v-1-3h-1l1-1 3-4 1-4-6-4-7-4-2-2-4-6-1-3-2-1-3-1-10-3-2-1h-4c-2 0-3 1-3 3-1 4 0 5 1 7l2 3v8c0 2-2 3-4 4l-1-1h-5l-2-1-4-3-4-1-5-2-1-1-2-1h-4v1l-1 1h-2-4l-3-4-1-3c-1-2-2-2-4-2l-5 1-3 2-4-1-5 2v4l-1 1-1 2h-1l-2-1c-4 0-5 1-7 2v0l-1-2-2-4-3-5-2-6v-2l1-1 1-1 1-1-1-3-5-6-2-1h-1l-1-4-1-2h-4l1-2 2-6c3-2 5-4 10-3l4 4c6 5 12-3 17-5 2-1 9-2 11-4 2-1 4-7 5-10l6-8 6 1 9-2 5-6 13-14 6-1 6-2 6-6c7-8 4-13 8-22l4-6 3-9c-2-3 0-5 0-8l1-2 3-7c2-2 7-3 10-3l9-4 7-1 2-1 2 3 9 14 3 4 2 5 3 7 8 6 5 2 1 2 1 2 1 2v1a267 267 0 0 0-2 7l2 5 3 2v10l-2 3-6 3h-3l-3 4-1 2h-3l-3 2v4l2 2 5 1h8l3-2 1-3v-1l2 2h-1a616 616 0 0 0 1 1h1s2-2 1-3v1l-1 2z" fill="currentColor" transform="translate(-22 -26) scale(.54274)" id="trenciansky" @click="openStores"/>
    <path d="M288 115l5-3 2-4 2-8 2-2 3-3 9-11 1-3c4-6 6-6 12-8l7 2 8 2 24-9h1l5 1c3 1 6-1 9-1l2 1 3 3c0 4-3 3-1 8v4h1l1 4 2 2c-1 2-4 3-3 6l1 2h10l2-4h2l2-1h2l2 2h1l3 1 3-1h2l3-1h3l1-1-2-5 3-3h3c0-2 1-4-1-6l3-6v-4c3 0 3-6 7-4l1-1h5l2 1 5-2h1c0-2 0-7 2-8 3 0 5-1 5-4l8-3 4 4 2 1 4 3c2 3 2 6 2 9l2 3c1 5 2 11 6 15h2l3 4 3-2h1c0 3-1 4 1 6l-3 5 4 1 7 1 3 3 5-5 3-1 4 2v4l2 7-1 4 2 5c-1 2-3 5-2 6l2 1 2 3-1 2-4 2c-1 5-1 3-3 6l-2 6 1 2 4 2 2-1 6 2 4-1a39 39 0 0 0 2 4l1 3 1 8 1 3 1 1 4-1 1-1 1-1 1 1 1 2 3 3h3l3 1h3l-1 1-1 4v10l-1 2-1 1-6 2a2 2 0 0 0-1 3l3 4 3 4 3 3 1 3 1 3 3 1-4 3h-1l-2-1-23 2-4-2h-5l-6 3-2 1h-1l-2-2-4-3c-1-2-2-2-4-1h-1v1l-3-2-3-3c-4-2-8-1-12-1l-12 2-4 1-3 1-6 1-3 1-2 1-1 2-6 2-3 5v2a15 15 0 0 1-8 2v-3l-1-3-1-1-6-2-6 2-3 1-8 2h-7l-3-1h-1l-1 1-7 7-2 3 1 3 1 1-2 3v3l2 3c1 2 2 3 1 6v1h-3l-7 3-2-1-3-2-3-4-3-1-3 2-2 2-5-3-2-1v-4l-2-4c-1-3-5-6-7-7l-2-1h-1a11 11 0 0 1-1-5l1-1 1-3v-2-1l-1-1 1-5 1-3v-2l-3-4-4-1h-4l-2-1-2-1-5 1v-1-1l-4-1-2 1-1 2-1 3v1h-2a21 21 0 0 1-9 0v-1h1l3-1c1 0 2-1 2-3l1-1v-1h3l8-4 3-6v-6-3l-1-3-2-3c-1 0-2-1-2-3a373 373 0 0 1 2-9l-1-1h-1v-3l-2-3-6-2-7-6-2-5-3-6-3-4-9-15-2-2z" fill="currentColor" transform="translate(-22 -26) scale(.54274)" id="zilinsky" @click="openStores"/>
    <path d="M530 145h1c3-3 2-5 6-7 4-1 6 1 9 1l2 2 2 1 1 4 10 5h2v-2l-1-1c0-3 2-6 4-8l-1-3v-4l2-3c2-3 3-10 5-12l2 2 2-1c0-4 3-4 3-7 2 0 4-1 5-3 1 2 4 1 6 0l1 1h9l1-8v-1l-1-2 1-2 2-1 1 1h5l1 1v1l1 1c1 1 3 0 4-1l1-1v-1l1-1 1 1 1 1v-2h-1l1-2 2 1v-2l5 1 5 4 1 1 9 4 3-2h2v-3l2-1v-3l1-1 2 2 2 1v1h1c3-1 7-5 9-4l2-3v5h3v2l2 1-1 2 1 2v2l1 2 4-1-1 2v1l3-1 1 1-1 2 1 2h2l1 2c2 1 5-2 6-3 3 0 4 0 6-2l1 1v3l1 3c1 3 0 3 4 5v1l7-3 3 1-1-4 3-2 1-4 6-4h3l1 1 4-3v-1l-3-3-2-2-3-3 1-2c1-2 5-2 7-2h2l3-2c2 0 5 3 6 5l4 3h3l2-4v-2c1-2 5-2 7-5l-1-1 3-1 4 2h6l4 3 3 1 3-1c2 2 3 4 6 5l3-1 2 1 2-1v-2l1-1 3 2 2 1 1-1v-3h3l2-2h2l1 2h2l6-3h1c4 3 3 8 8 8l3-1 4 1v2c0 2 4 4 6 6l1 5h2v1h1v-4c1-2 6-4 8-5h1l2 5 5 1 2 3c3 0 5 0 6 2l3-1 2 1 2 6c1 3 3 5 6 7l2 2v4l1 1v1c1 2-1 6 1 9 3 4 8 2 11 5h5l1 4c2 0 6 1 7 3l4-2 3 1c1 2 0 2-1 4v2l5 1 9 3 3-2h6l1 2 2 2c1 2 2 6 5 6h8l4 3c2-1 9-1 11 1v1l-1 1v10c-1 2-3 4-2 7l-3 3c-2 0-3-2-5-1l-1 1-2 5-2 2-2 3-2 3c-1 2-2 5 0 6l-2 2v5l-2 2-17-1a187 187 0 0 0-6 1v-2l-1-5 1-4-1-3c0-1-2-2-5-2l-4 2c-2 0-2 1-3 2l-1 2-3 3c-2 0-4 1-5 3l-1 2v7h-4l-2 1-2 1h-14c-2 0-3-1-4-3l-3-4-8-4-13-4-2 1h-1l-3 2c-1 3-1 5 1 7l1 1v11l1 3 1 3h-4l-3 1-5 4-3 1-4-2-4-2h-8l-2-2-5-2h-4v-1-2-4l-3-2-1-2-3-8-2-2h-3l-3 2c-1-3-3-4-4-4l-2 2-3-1-3 1-1 4v4l-1 3h-8l-2 2-1 2v1h-5l-1-2-1-1v-2l3-3v-3l-1-2-3-1h-1l-4-2-3 1-1 2-1-2c-1-2-2-4-4-4h-3l-5 2h-1l-1-1-4-2h-1l-3 1c-3-2-3-4-3-5l-2-3-1-1-1-2-1-2-1-1h-4-2l-1 1-5-2-3-1-4-1h-3v-1l-1-3-1-1h-2v1h-1l-1-2-1-1-3-1-2 1h-1l-1 2v5h-3l-1-1-1-2-3-1h-2c-2 0-3 1-4 3v2h-1a18 18 0 0 1-5 4l-1 1-1-1v-1l-1-1c0-2 0-4-2-5h-8v-2c-3-3-6-4-10-4h-2l-2 1v2l1 1v1l-1 1v-1l-2-3-2-2h-4l-2 1-10 7-7 3-2 4-1 2 2 2h2l2 1h1l-4 3-4 2-1 3-1 2-1 1-3-1-2-1-6-5-4-1-1 1-1 1v1l-10-1h-5l-4-1 4-3 1-2-2-3-2-1-2-1v-1-2l-3-4-4-5-1-1 4-1c2-1 3-4 4-7v-9l1-4 1-3-2-3h-5l-3-1h-2l-1-1-1-1c0-2-1-3-3-3-1-1-3 0-4 1h-1v-2l-1-6-2-4-2-4z" fill="currentColor" transform="translate(-22 -26) scale(.54274)" id="presovsky" @click="openStores"/>
  </svg>
</template>

<script>
export default {
  name: 'WidgetMap',
  methods: {
    openStores(event) {
      const region = event.target.id;
      window.open(`https://www.generali.sk/kontakt/pobocky/?region=${region}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-map {
  display: block;
  width: 100%;
  path {
    color: #E64D35;
    cursor: pointer;
    &:hover {
      color: #BA1A17;
    }
  }
}
</style>
