<template>
  <div class="contact-form">
    <div class="overlay" v-if="isLoading">
      <BaseSpinner
        class="spinner"
        variant="primary"
        :size="40"
      />
    </div>
    <InfoBox
      variant="success"
      v-if="isSent"
    >
      Ďakujeme, formulár bol odoslaný.
    </InfoBox>
    <form
      ref="form"
      class="form"
      action="https://www.generali.sk/_services/form-mailer/"
      method="post"
      @submit.prevent="submit()"
    >
      <input
        type="hidden"
        name="_from_address"
        value="distribucia@generali.sk"
      />
      <input
        type="hidden"
        name="_from_name"
        value="Generali Slovenská distribúcia"
      />
      <input
        type="hidden"
        name="_recipient"
        value="generali.sk@generali.com "
      />
      <input
        type="hidden"
        name="_subject"
        value="Podnet -> Generalislovenskadistribucia.sk"
      />
      <FormGroup
        label="Meno a priezvisko"
        class="fg"
      >
        <InputText
          required
          name="Meno"
        />
      </FormGroup>
      <FormGroup
        label="Email"
        class="fg"
      >
        <InputText
          type="email"
          required
          name="Email"
        />
      </FormGroup>
      <FormGroup
        label="Vaša otázka"
        class="fg"
      >
        <InputTextarea
          required
          name="Správa"
        />
      </FormGroup>
      <p class="fineprint">
        Odoslaním formulára dôjde k spracúvaniu vašich osobných údajov. Viac v <router-link to="/osobne-udaje" target="_blank">Info o spracúvaní osobných údajov</router-link>.
      </p>
      <div class="footer">
        <BaseButton>
          Odoslať
        </BaseButton>
      </div>
    </form>
  </div>
</template>

<script>
import FormGroup from '@gds/components/FormGroup';
import InputText from '@gds/components/InputText';
import InputTextarea from '@gds/components/InputTextarea';
import BaseButton from '@gds/components/BaseButton';
import BaseSpinner from '@gds/components/BaseSpinner';
import InfoBox from '@gds/components/InfoBox';

import ajaxSend from '@/util/ajaxSend';

export default {
  components: {
    FormGroup,
    InputText,
    InputTextarea,
    BaseButton,
    InfoBox,
    BaseSpinner,
  },
  data: () => ({
    isLoading: false,
    isSent: false,
  }),
  methods: {
    submit() {
      this.isLoading = true;
      const { form } = this.$refs;
      const data = new FormData(form);

      const onSuccess = () => {
        this.isLoading = false;
        this.isSent = true;
        form.reset();
      };

      const onError = () => {
        this.isLoading = false;
        // eslint-disable-next-line
        alert('Formulár sa nepodarilo odslať, skúste neskôr');
      };

      ajaxSend(form.method, form.action, data, onSuccess, onError);
    },
  },
};
</script>

<style lang="scss" scoped>

.contact-form {
  position: relative;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(white, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.form {
  .fineprint {
    font-size: $font-size-200;
    margin-bottom: $spacer-1;
    a {
      color: $primary;
    }
  }
  .footer {
    text-align: center;
  }
  .fg {
    margin-bottom: $spacer-0;
  }
}
</style>
