<template>
  <section class="section section-hero">
    <div class="container">
      <div class="header">
        <h1 class="title">Celoživotné partnerstvo</h1>
        <p class="lead">
          Našich 800 obchodníkov žije medzi vami. Preto vám rozumejú a dokážu najlepšie poradiť. Správne partnerstvo by malo trvať minimálne celý život.
        </p>
      </div>
    </div>
    <img class="image" src="@/assets/hero-image3.jpg" alt="Celoživotné partnerstvo">
  </section>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
.section-hero {
  padding-top: $spacer-2;
  .header {
    max-width: 800px;
    position: relative;
    margin-bottom: $spacer-2;
  }
  .title {
    font-size: $font-size-500;
    margin-bottom: $spacer-1;
  }
  .lead {
    font-size: $font-size-300;
  }
  .image {
    display: block;
    width: 100%;
    height: auto;
  }
  @media screen and (min-width: 768px) {
    .title {
      font-size: $font-size-600;;
    }
    .lead {
      font-size: $font-size-500;
    }
  }
}
</style>
