<template>
  <section class="section section-contact">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <BaseHeading>
            Napíšte nám
          </BaseHeading>
          <ContactForm/>
        </div>
        <div class="col-lg-4">
          <BaseHeading noTopLine>
            Navštívte pobočku
          </BaseHeading>
          <div class="colmain">
            <WidgetMap/>
          </div>
          <div class="colfoot">
            <BaseButton variant="secondary" @click="openURL('https://www.generali.sk/kontakt/pobocky/')">Pobočky</BaseButton>
            <BaseButton variant="secondary" @click="openURL('https://www.generali.sk/kontakt/mapa-firemnych-centier/')">Firemné centrá</BaseButton>
          </div>
        </div>
        <div class="col-lg-4">
          <BaseHeading noTopLine>
            Volajte
          </BaseHeading>
          <div class="colmain">
            <a href="tel:+421238111117" class="phone-number">
              <img src="@/assets/icons/phone.svg" class="icon" alt="Telefón">
              <div class="number">02/38 11 11 17</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BaseButton from '@gds/components/BaseButton';
import BaseHeading from '@/components/BaseHeading.vue';
import ContactForm from '@/components/ContactForm.vue';
import WidgetMap from '@/components/WidgetMap.vue';

export default {
  components: {
    BaseHeading,
    BaseButton,
    ContactForm,
    WidgetMap,
  },
  data: () => ({
    fields: {

    },
  }),
  methods: {
    openURL(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.colmain {
  height: 408px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.colfoot {
  text-align: center;
  * {
    margin: 0 4px;
  }
}

.phone-number {
  text-align: center;
  color: $red-40;
  font-weight: 700;
  font-size: $font-size-500;
  text-decoration: none;
  .icon {
    margin: 0 auto $spacer-1;
    width: 96px;
    height: 96px;
  }
}
</style>
