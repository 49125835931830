<template>
  <SectionText
    heading="Informácie o ochrane osobných údajov"
  >
    <div class="row">
      <div class="col-md-8">
        <p>Ochrana Vášho súkromia a Vašich osobných údajov patrí medzi naše priority, ubezpečujeme Vás, že Vaše osobné údaje spracovávame v súlade s platnou právnou úpravou, predovšetkým v súlade s Nariadením Európskeho parlamentu a Rady (EÚ) 2016/679 z 27. apríla 2016 o ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom pohybe takýchto údajov (ďalej len „Nariadenie“). Kladieme dôraz na najvyššiu možnú mieru zabezpečenia, dbáme na riadny výber zmluvných sprostredkovateľov a striktné dodržiavanie všetkých pravidiel, ktorými sme viazaní. Všetky osoby, ktoré prídu do styku s osobnými údajmi pri plnení svojich pracovných alebo zmluvných povinností, sú viazané povinnosťou mlčanlivosti ako o osobných údajoch, tak i o prijatých bezpečnostných opatreniach, pričom táto povinnosť trvá neobmedzene i po skončení pracovného pomeru alebo zmluvného vzťahu.</p>
        <div class="section-text-button-wrap">
          <BaseButton
            block
            @click="$router.push('/osobne-udaje')"
          >
            Čítať viac
          </BaseButton>
        </div>
      </div>
      <div class="col-md-4">
        <div class="icon-wrap">
          <img class="icon" src="@/assets/icons/privacy.svg?data" alt="Ochrana osobných údajov">
        </div>
      </div>
    </div>
  </SectionText>
</template>

<script>
import BaseButton from '@gds/components/BaseButton';
import SectionText from '@/components/SectionText.vue';

export default {
  components: {
    BaseButton,
    SectionText,
  },
  methods: {
    openURL(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.inner {
  max-width: 580px;
}

.icon-wrap {
  border: 1px solid $gray-300;
  height: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    flex: 0 0 100px;
    height: 100px;
    width: 100px;
    margin-bottom: 0;
  }
}
</style>
