<template>
  <SectionText
    heading="Základné informácie"
    class="section-company-info"
  >
    <div class="inner">
      <table class="company-table">
        <tbody>
          <tr>
            <th scope="row">Názov spoločnosti</th>
            <td>Generali Slovenská distribúcia, a. s.</td>
          </tr>
          <tr>
            <th scope="row">Sídlo</th>
            <td>Lamačská cesta 6257/ 3/A, Bratislava - mestská časť Karlova Ves 841 04</td>
          </tr>
          <tr>
            <th scope="row">IČO</th>
            <td>54 067 197</td>
          </tr>
          <tr>
            <th scope="row">DIČ</th>
            <td>2121572156</td>
          </tr>
          <tr>
            <th scope="row">Právna forma:</th>
            <td>akciová spoločnosť</td>
          </tr>
          <tr>
            <th scope="row">Tel.:</th>
            <td><a href="tel:+421238111117">02/38 11 11 17</a></td>
          </tr>
          <tr>
            <th scope="row">E-mail:</th>
            <td><a href="mailto:generali.sk@generali.com">generali.sk@generali.com</a></td>
          </tr>
          <tr>
            <th scope="row">Web:</th>
            <td><a href="https://www.generalislovenskadistribucia.sk" target="_blank">www.generalislovenskadistribucia.sk</a></td>
          </tr>

        </tbody>
      </table>
      <p class="fineprint">Spoločnosť je zapísaná v Obchodnom registri Okresného súdu Bratislava I, oddiel: Sa, vložka č.: 7302/B. Spoločnosť patrí do skupiny Generali, ktorá je uvedená v talianskom zozname skupín poisťovní vedeným IVASS, pod č. 026..</p>
      <p class="fineprint">Generali Slovenská distribúcia, a. s., je zapísaná v zozname samostatných finančných agentov s registračným číslom 263605 v podregistri poistenia alebo zaistenia; podregistri kapitálového trhu; podregistri poskytovania úverov, úverov na bývanie a spotrebiteľských úverov. Zápis v zozname je možné overiť na webovej stránke <a href="https://regfap.nbs.sk" target="_blank" rel="noopener">Národnej banky Slovenska</a></p>

    </div>
  </SectionText>
</template>

<script>
import SectionText from '@/components/SectionText.vue';

export default {
  components: {
    SectionText,
  },
};
</script>

<style lang="scss" scoped>
.section-company-info {
  a {
    color: $red-10;
  }
}

.company-table {
  text-align: left;
  width: 100%;
  word-break: break-word;
  overflow-wrap: anywhere;
  th, td {
    vertical-align: top;
    padding: 0 $spacer-1 $spacer-0 0;
  }
  th {
    font-weight: 400;
  }
  td {
    font-weight: 700;
  }
}
</style>
