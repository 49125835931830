<template>
  <section class="section section-features">
    <div class="container">
      <BaseHeading>
        Prečo s nami?
      </BaseHeading>
      <div class="row">
        <div
          v-for="(item, i) in items"
          :key="i"
          class="col-md-6 col-lg-3"
        >
          <FeatureItem
            :title="item.title"
            :titleImage="item.titleImage"
            :subtitle="item.subtitle"
            :text="item.text"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BaseHeading from '@/components/BaseHeading.vue';
import FeatureItem from '@/components/FeatureItem.vue';

export default {
  components: {
    BaseHeading,
    FeatureItem,
  },
  data: () => ({
    items: [
      {
        title: 'Celoživotný partner',
        // titleImage: require('@/assets/celozivotny-partner.png'),
        subtitle: 'Celoživotný partner',
        text: 'Chceme byť celoživotným partnerom našich klientov a preto im prinášame širokú ponuku finančných produktov pre rôzne životné situácie, v ktorej sa ocitnú – či pôjde o narodenie dieťaťa alebo kúpu nehnuteľnosti či vozidla.',
      },
      {
        title: '800<br> obchodníkov',
        subtitle: 'Produktové portfólio',
        text: 'Generali Slovenská distribúcia poskytuje klientom komplexné finančné sprostredkovanie v oblasti poistenia, úverov a investícií. Ponúkame kvalitné finančné produkty od partnerov, s ktorými dlhodobo spolupracujeme. Naši obchodníci klientom pomôžu aj v otázke zdravotného poistenia či dôchodku, sprostredkujú im kvalitné riešenie v rámci II. piliera.',
      },
      {
        title: 'Viac ako<br> 150 pobočiek',
        subtitle: 'Dostupnosť a pokrytie',
        text: 'Generali Slovenská distribúcia patrí medzi najväčších finančných sprostredkovateľov na slovenskom trhu. V prípade potreby môžete využiť služby našich 800 obchodníkov vo viac ako 150 pobočkách na celom Slovensku.',
      },
      {
        title: 'Skupina<br> Generali',
        subtitle: 'Skupina Generali',
        text: 'Sme súčasťou Skupiny Generali globálneho lídra v oblasti poisťovníctva a asset manažmentu.',
      },
    ],
  }),
};
</script>

<style>

</style>
