<template>
  <h2
    class="base-heading"
    :class="{ '-notopline': noTopLine }"
  >
    <slot/>
  </h2>
</template>

<script>
export default {
  props: {
    noTopLine: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.base-heading {
  font-size: $font-size-600;
  font-weight: 400;
  color: $gray-900;

  padding-top: $spacer-2;
  margin-bottom: $spacer-2;
  position: relative;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: 96px;
    border-top: 2px solid $red-10;
  }
  &.-notopline {
    &::before {
      display: none;
    }
  }
}
</style>
