<template>
  <section class="section section-partners">
    <div class="container">
      <BaseHeading>
        Partneri
      </BaseHeading>
      <ul class="logos">
        <li
          v-for="(item, i) in partners"
          :key="i"
        >
          <img
            :src="item.image"
            :alt="item.label"
            :title="item.label"
            loading="lazy"
          >
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
/* eslint-disable global-require */
import BaseHeading from '@/components/BaseHeading.vue';

export default {
  props: {
    heading: String,
  },
  components: {
    BaseHeading,
  },
  data: () => ({
    partners: [
      {
        image: require('@/assets/partners/generali.svg'),
        label: 'Generali',
      },
      {
        image: require('@/assets/partners/generali-investments.svg'),
        label: 'Generali Investments',
      },
      {
        image: require('@/assets/partners/vub-isg.svg'),
        label: 'VÚB',
      },
      {
        image: require('@/assets/partners/slsp.png'),
        label: 'Slovenská sporiteľňa',
      },
      {
        image: require('@/assets/partners/tb.svg'),
        label: 'Tatra banka',
      },
      {
        image: require('@/assets/partners/unicredit.svg'),
        label: 'Unicredit bank',
      },
      {
        image: require('@/assets/partners/csob.svg'),
        label: 'ČSOB banka',
      },
      {
        image: require('@/assets/partners/pss.svg'),
        label: 'PSS',
      },
    ],
  }),
};
</script>

<style lang="scss">
.section-partners {
  .logos {
    padding: 0;
    margin: 0 auto;
    max-width: 900px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  li {
    margin: $spacer-1;
  }
  img {
    display: block;
    max-height: 35px;
    max-width: 140px;
    width: auto;
    height: auto;
  }
  @media screen and (min-width: 768px) {
    li {
      margin: $spacer-1 $spacer-2;
    }
    img {
      max-height: 50px;
      max-width: 200px;
    }
  }
}
</style>
