<template>
  <button
    class="base-button"
    :class="cssClasses"
    @click="handleClick"
  >
    <slot></slot>
  </button>
</template>

<script>
import './BaseButton.scss';

export default {
  name: 'BaseButton',
  props: {
    size: {
      type: String,
      default: 'regular',
      validator: (value) => ['regular', 'small', 'square'].includes(value),
    },
    variant: {
      type: String,
      default: 'primary',
      validator: (value) => ['primary', 'secondary', 'inputlike'].includes(value),
    },
    block: {
      type: Boolean,
      default: false,
    },
    // TODO: Add disabled prop to element, but prevent double tap zooming
    disabled: {
      type: Boolean,
      default: false,
    },
    allowRepeat: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      repeater: null,
    };
  },
  computed: {
    cssClasses() {
      return [
        `-size-${this.size}`,
        `-variant-${this.variant}`,
        (this.disabled) ? '-disabled' : '',
        (this.block) ? '-block' : '',
      ];
    },
  },
  methods: {
    handleClick(event) {
      this.$emit('click', event);
    },
    repeatStart() {
      this.repeater = setInterval(this.handleClick, 250);
    },
    repeatEnd() {
      clearInterval(this.repeater);
    },
    initRepeat() {
      this.$el.addEventListener('mousedown', this.repeatStart);
      this.$el.addEventListener('touchstart', this.repeatStart);
      this.$el.addEventListener('mouseup', this.repeatEnd);
      this.$el.addEventListener('touchend', this.repeatEnd);
      this.$el.addEventListener('blur', this.repeatEnd);
    },
  },
  mounted() {
    if (this.allowRepeat) {
      this.initRepeat();
    }
  },
};
</script>
