<template>
  <textarea
    class="input-textarea"
    :v-text="value"
    :name="name"
    :autocomplete="autocomplete"
    rows="5"
    @input="$emit('input', $event.target.value)"
    @change="$emit('change', $event.target.value)"
    @blur="$emit('blur', $event)"
  />
</template>

<script>
import './InputTextarea.scss';

export default {
  name: 'InputTextarea',
  props: {
    value: {
      type: String,
    },
    name: {
      type: String,
    },
    autocomplete: {
      type: String,
      default: 'on',
    },
  },
};
</script>
