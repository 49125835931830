import { render, staticRenderFns } from "./InfoBox.vue?vue&type=template&id=ab9b4ac0&"
import script from "./InfoBox.vue?vue&type=script&lang=js&"
export * from "./InfoBox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../gsd-website/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports