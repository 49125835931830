<template>
  <section class="section section-products">
    <div class="container">
      <div class="row align-items-end">
        <div class="col-md">
          <BaseHeading>
            {{ title }}
          </BaseHeading>
        </div>
        <div class="tabswitcher col-md-6 col-lg-4">
          <InputOptions
            v-if="groups.length > 1"
            v-model="activeGroup"
            :options="groups.map((group, i) => ({
              label: group.title,
              value: i,
            }))"
          />
        </div>
      </div>
      <div class="products">
        <ProductCard
          v-for="(item, i) in activeGroupItems"
          :key="i"
          :icon="item.icon"
          :title="item.title"
          :buttonMore="item.buttonMore"
          :buttonCalculator="item.buttonCalculator"
        />
      </div>
    </div>
  </section>
</template>

<script>
import InputOptions from '@gds/components/InputOptions';
import BaseHeading from '@/components/BaseHeading.vue';
import ProductCard from '@/components/ProductCard.vue';

export default {
  props: {
    title: String,
    groups: Array,
  },
  components: {
    InputOptions,
    BaseHeading,
    ProductCard,
  },
  data: () => ({
    activeGroup: 0,
  }),
  computed: {
    activeGroupItems() {
      return this.groups[this.activeGroup].items;
    },
  },
};
</script>

<style lang="scss" scoped>
.section-products {
  .tabswitcher {
    padding-bottom: $spacer-2;
  }
  .products {
    display: flex;
    flex-wrap: wrap;
    padding-left: 1px;
  }
}
</style>
