<template>
  <SectionProducts
    title="Poistenie"
    :groups="groups"
  />
</template>

<script>
/* eslint-disable global-require */
import SectionProducts from '@/components/SectionProducts.vue';

export default {
  props: {
    heading: String,
  },
  components: {
    SectionProducts,
  },
  data: () => ({
    groups: [
      {
        title: 'Pre občanov',
        slug: 'pre-obcanov',
        items: [
          {
            icon: require('@/assets/icons/lifesaver.svg?data'),
            title: 'Životné poistenie',
            buttonMore: 'https://www.generali.sk/pre-obcanov/zivotne-poistenie/',
            buttonCalculator: 'https://www.generali.sk/poistna-kalkulacka/zivotne-poistenie/',
          },
          {
            icon: require('@/assets/icons/plane.svg?data'),
            title: 'Cestovné poistenie',
            buttonMore: 'https://www.generali.sk/pre-obcanov/cestovne-poistenie/',
            buttonCalculator: 'https://kalkulacka.generali.sk/cp/',
          },
          {
            icon: require('@/assets/icons/car-front.svg?data'),
            title: 'PZP',
            buttonMore: 'https://www.generali.sk/pre-obcanov/poistenie-auta/pzp/',
            buttonCalculator: 'https://www.generali.sk/poistna-kalkulacka/pzp/',
          },
          {
            icon: require('@/assets/icons/car-breakdown.svg?data'),
            title: 'Havarijné',
            buttonMore: 'https://www.generali.sk/pre-obcanov/poistenie-auta/havarijne-poistenie/',
            buttonCalculator: 'https://www.generali.sk/poistna-kalkulacka/havarijne/',
          },
          {
            icon: require('@/assets/icons/home-insurance.svg?data'),
            title: 'Poistenie majetku',
            buttonMore: 'https://www.generali.sk/pre-obcanov/poistenie-majetku/',
            buttonCalculator: 'https://www.generali.sk/poistna-kalkulacka/majetok/',
          },
          {
            icon: require('@/assets/icons/broken-things.svg?data'),
            title: 'Poistenie zodpovednosti za škodu',
            buttonMore: 'https://www.generali.sk/pre-obcanov/poistenie-zodpovednosti-za-skodu/poistenie-zodpovednosti-za-skodu-sposobenu-zamestnavatelovi/',
          },
        ],
      },
      {
        title: 'Pre firmy',
        slug: 'pre-firmy',
        items: [
          {
            icon: require('@/assets/icons/tools.svg?data'),
            title: 'Profi extra',
            buttonMore: 'https://www.generali.sk/pre-podnikatelov/profi-extra/',
          },
          {
            icon: require('@/assets/icons/factory.svg?data'),
            title: 'Poistenie majetku a prerušenia prevádzky',
            buttonMore: 'https://www.generali.sk/pre-podnikatelov/majetok-a-prerusenie-prevadzky/',
          },
          {
            icon: require('@/assets/icons/broken-things.svg?data'),
            title: 'Zodpovednosť za škodu tretím osobám',
            buttonMore: 'https://www.generali.sk/pre-podnikatelov/zodpovednost-za-skodu-tretim-osobam/',
          },
          {
            icon: require('@/assets/icons/laptop.svg?data'),
            title: 'Stroje a elektronika',
            buttonMore: 'https://www.generali.sk/pre-podnikatelov/stroje-a-elektronika/',
          },
          {
            icon: require('@/assets/icons/bus.svg?data'),
            title: 'Dopravca',
            buttonMore: 'https://www.generali.sk/pre-podnikatelov/dopravca/',
          },
          {
            icon: require('@/assets/icons/car-front.svg?data'),
            title: 'Vozidlá',
            buttonMore: 'https://www.generali.sk/pre-podnikatelov/vozidla/',
          },
          {
            icon: require('@/assets/icons/plane.svg?data'),
            title: 'Lietadlá',
            buttonMore: 'https://www.generali.sk/pre-podnikatelov/lietadla/',
          },
          {
            icon: require('@/assets/icons/boat.svg?data'),
            title: 'Plavidlá',
            buttonMore: 'https://www.generali.sk/pre-podnikatelov/plavidla/',
          },
          {
            icon: require('@/assets/icons/tractor.svg?data'),
            title: 'Poľnohospodárske poistenie',
            buttonMore: 'https://www.generali.sk/pre-podnikatelov/polnohospodarske-poistenie/',
          },
          {
            icon: require('@/assets/icons/network-people.svg?data'),
            title: 'Skupinové poistenia',
            buttonMore: 'https://www.generali.sk/pre-podnikatelov/skupinove-poistenia/',
          },

        ],
      },
    ],
  }),
};
</script>
