<template>
  <div class="site-header">
    <router-link to="/" class="home-link">
      <BrandBadge
        class="logo"
      />
    </router-link>
    <ul class="nav">
      <li><router-link class="red-10" :to="{ path: '/', hash: '#produkty' }">Produkty</router-link></li>
      <li><router-link class="red-40" :to="{ path: '/', hash: '#kariera' }">Kariéra</router-link></li>
      <li><router-link class="red-20" :to="{ path: '/', hash: '#o-nas' }">O nás</router-link></li>
      <li><router-link class="red-30" :to="{ path: '/', hash: '#kontakt' }">Kontakt</router-link></li>
      <li><router-link class="red-10" to="/podnety">Podnety</router-link></li>
    </ul>
    <a href="tel:+421238111117" class="phone">
      <img src="@/assets/icons/phone.svg" alt="Telefón">
      02/38 11 11 17
    </a>
  </div>
</template>

<script>
import BrandBadge from '@/components/BrandBadge.vue';

export default {
  components: {
    BrandBadge,
  },
};
</script>

<style lang="scss" scoped>

.site-header {
  background-image: url('../assets/bar1.svg');
  background-size: 8px 100%;
  background-position: left top;
  background-repeat: no-repeat;
  height: 72px;

  padding: 0 $spacer-1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .nav {
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;
    li + li {
      margin-left: 2px;
    }
    a {
      display: block;
      padding: 8px 12px;
      text-transform: uppercase;
      color: $gray-900;
      font-weight: 600;
      text-decoration: none;
      border-bottom: 4px solid $gray-500;
    }
    a:hover {
      background-color: $gray-500;
      color: $white;
    }
    a.red-10 {
      border-color: $red-10;
      &:hover { background-color: $red-10; }
    }
    a.red-20 {
      border-color: $red-20;
      &:hover { background-color: $red-20; }
    }
    a.red-30 {
      border-color: $red-30;
      &:hover { background-color: $red-30; }
    }
    a.red-40 {
      border-color: $red-40;
      &:hover { background-color: $red-40; }
    }

    @media screen and (min-width: 992px) {
      display: flex;
    }
  }
  .phone {
    color: $red-40;
    text-decoration: none;
    font-weight: 700;
    img {
      width: 24px;
      height: 24px;
      margin-right: 4px;
      vertical-align: middle;
    }
  }
  @media screen and (min-width: 992px) {
    height: 100px;
    padding: 0 $spacer-3;
  }
}

.home-link {
  text-decoration: none;
}
</style>
