<template>
  <div
    class="form-group"
    :class="{ '-invalid': shouldShowValidation && !isValid }"
  >
    <div class="header">
      <div class="label">
        {{ label }}
      </div>
      <TooltipSet
        v-if="tooltipText"
        buttonSize="small"
      >
        {{ tooltipText }}
      </TooltipSet>
    </div>
    <div class="main">
      <div class="control">
        <slot></slot>
      </div>
      <div
        v-if="helpText"
        class="help-text"
      >
        {{ helpText }}
      </div>
      <div
        v-if="!!$slots.afterMessage || afterMessageCurrent.message"
        class="after-message"
      >
        <TooltipWindow
          :arrowOffset="afterMessageCurrent.offset"
          isStatic
          :variant="afterMessageVariant"
          :accent="afterMessageAccent"
          :padding="afterMessagePadding"
        >
          <slot v-if="!!$slots.afterMessage" name="afterMessage"></slot>
          <span v-else>{{ afterMessageCurrent.message }}</span>
        </TooltipWindow>
      </div>
      <div class="invalid-message" v-if="shouldShowValidation && !isValid">
        {{ validationIssues[0] }}
      </div>
    </div>
  </div>
</template>

<script>
import './FormGroup.scss';
import TooltipSet from '@gds/components/TooltipSet';
import TooltipWindow from '@gds/components/TooltipWindow';

export default {
  name: 'FormGroup',
  components: {
    TooltipSet,
    TooltipWindow,
  },
  props: {
    label: {
      type: String,
    },
    tooltipText: {
      type: String,
    },
    helpText: {
      type: String,
    },
    // After message props
    afterMessage: {
      type: String,
    },
    afterMessageList: {
      type: Array,
    },
    afterMessageActive: {
      type: [String, Number, Boolean],
      default: null,
    },
    afterMessageVariant: {
      type: String,
      default: 'light',
    },
    afterMessageAccent: {
      type: String,
      default: 'none',
    },
    afterMessageArrowOffset: {
      type: Number,
    },
    afterMessagePadding: {
      type: Boolean,
      default: true,
    },
    // Validation props
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      inputReference: '', // Reference to v-model (for Validation)
    };
  },
  methods: {
    registerValidation() {
      if (!this.validationSuitable) {
        return;
      }

      // Find inputElement
      let inputElement = this.$slots.default[0];
      if (!inputElement.data?.model?.expression) {
        // May be using InputGroup; find first nested child
        inputElement = inputElement?.componentOptions?.children[0];
      }
      this.inputReference = inputElement?.data.model.expression;

      if (!this.inputReference) {
        return;
      }

      // Setup fieldmap
      this.$set(
        this.$v.validator.fieldsMap,
        this.inputReference,
        { valid: this.isValid, touched: false },
      );

      // Hook change event
      this.$nextTick(() => {
        const controlElement = inputElement.elm;
        controlElement.addEventListener('change', () => {
          this.inputReferenceStatus.touched = true;
        });
      });
    },
    unregisterValidation() {
      if (!this.validationSuitable) {
        return;
      }

      this.$delete(this.$v.validator.fieldsMap, this.inputReference);
    },
  },
  computed: {
    afterMessageCurrent() {
      if (this.afterMessage) {
        return {
          message: this.afterMessage,
          offset: this.afterMessageArrowOffset,
        };
      }
      if (this.afterMessageList && this.afterMessageList.length) {
        // eslint-disable-next-line arrow-body-style
        const matchedIndex = this.afterMessageList.findIndex((message) => {
          return message.for === this.afterMessageActive;
        });
        if (matchedIndex !== -1) {
          const piece = 100 / this.afterMessageList.length;
          const offset = (piece * matchedIndex) + (piece / 2);
          return {
            message: this.afterMessageList[matchedIndex].message,
            offset,
          };
        }
      }
      return {
        message: null,
        offset: this.afterMessageArrowOffset,
      };
    },
    validationSuitable() {
      return this.rules.length && !!this.$v;
    },
    validationIssues() {
      if (!this.validationSuitable) {
        return [];
      }

      const issues = [];

      this.rules.filter(Boolean).forEach((ruleRef) => {
        try {
          const rule = (typeof ruleRef === 'object')
            ? ruleRef
            : this.$v.rules[ruleRef]();

          try {
            if (!rule.pass(this.inputValue)) {
              issues.push(rule.message());
            }
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log(`Error in ${ruleRef} validation function: ${e}.`);
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(`Could not find rule: ${ruleRef}.`);
        }
      });

      return issues;
    },
    isValid() {
      return this.validationIssues.length === 0;
    },
    shouldShowValidation() {
      return this.validationSuitable && this.inputReferenceStatus?.touched;
    },
    inputValue() {
      if (!this.inputReference) return null;

      const path = this.inputReference.split('.');
      let target = this.$parent;
      path.forEach((part) => {
        target = target[part];
      });
      return target;
    },
    inputReferenceStatus() {
      return this.$v?.validator.fieldsMap[this.inputReference];
    },
  },
  watch: {
    isValid(newValue) {
      // Update validation status to map
      if (this.validationSuitable) {
        this.$set(
          this.inputReferenceStatus,
          'valid',
          newValue,
        );
      }
    },
  },
  mounted() {
    this.registerValidation();
  },
  beforeDestroy() {
    this.unregisterValidation();
  },
};
</script>
