<template>
  <SectionProducts
    title="Investície a bankové produkty"
    :groups="groups"
  />
</template>

<script>
/* eslint-disable global-require */
import SectionProducts from '@/components/SectionProducts.vue';

export default {
  props: {
    heading: String,
  },
  components: {
    SectionProducts,
  },
  data: () => ({
    groups: [
      {
        title: 'Pre občanov',
        slug: 'pre-obcanov',
        items: [
          {
            icon: require('@/assets/icons/home-insurance.svg?data'),
            title: 'Hypotéky a spotrebné úvery',
            buttonMore: 'https://www.generali.sk/pre-obcanov/hypoteky-a-uvery/',
          },
          {
            icon: require('@/assets/icons/coins.svg?data'),
            title: 'Investície a sporenia',
          },
          {
            icon: require('@/assets/icons/factory.svg?data'),
            title: 'Úvery pre podnikateľov',
          },
        ],
      },
    ],
  }),
};
</script>
