<template>
  <div class="site-footer">
    <div class="container">
      <h2 class="invisible-title">Pätka</h2>
      <div class="row">
        <nav
          class="footer-nav col-md-3"
          v-for="(group, i) in groups"
          :key="i"
        >
          <h3 class="group-title">{{ group.title }}</h3>
          <ul>
            <li
              v-for="(item, j) in group.items"
              :key="j"
            >
              <a
                :href="item.link"
                :class="item.class"
                :target="!item.noSpecialProps ? '_blank' : null"
                :rel="!item.noSpecialProps ? 'noopener' : null"
              >
                {{ item.label }}
              </a>
            </li>
          </ul>
        </nav>
        <div class="footer-legal col-md-3 align-self-end">
          <BrandBadge
            negative
            class="logo"
          />
          <p class="name">2021 Generali Slovenská distribúcia a.s.</p>
          <p class="group">Súčasť Generali Group</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BrandBadge from '@/components/BrandBadge.vue';

export default {
  components: {
    BrandBadge,
  },
  data: () => ({
    groups: [
      {
        title: 'Pre občanov',
        items: [
          { label: 'Životné poistenie', link: 'https://www.generali.sk/pre-obcanov/zivotne-poistenie/' },
          { label: 'Cestovné poistenie', link: 'https://www.generali.sk/pre-obcanov/cestovne-poistenie/' },
          { label: 'Poistenie majetku', link: 'https://www.generali.sk/pre-obcanov/poistenie-majetku/' },
          { label: 'Poistenie auta', link: 'https://www.generali.sk/pre-obcanov/poistenie-auta/' },
          { label: 'Poistenie zodpovednosti za škodu', link: 'https://www.generali.sk/pre-obcanov/poistenie-zodpovednosti-za-skodu/' },
          { label: 'Fondy', link: 'https://www.generali.sk/pre-obcanov/fondy/' },
          { label: 'Dôchodky', link: 'https://www.generali.sk/pre-obcanov/dochodky/' },
          { label: 'Hypotéky', link: 'https://www.generali.sk/pre-obcanov/hypoteky/' },
        ],
      },
      {
        title: 'Pre podnikateľov',
        items: [
          { label: 'Profi extra', link: 'https://www.generali.sk/pre-podnikatelov/profi-extra/' },
          { label: 'Poistenie majetku a prerušenia prevádzky', link: 'https://www.generali.sk/pre-podnikatelov/majetok-a-prerusenie-prevadzky/' },
          { label: 'Zodpovednosť za škodu tretím osobám', link: 'https://www.generali.sk/pre-podnikatelov/zodpovednost-za-skodu-tretim-osobam/' },
          { label: 'Stroje a elektronika', link: 'https://www.generali.sk/pre-podnikatelov/stroje-a-elektronika/' },
          { label: 'Stavebné a montážne poistenie', link: 'https://www.generali.sk/pre-podnikatelov/stavba-montaz/' },
          { label: 'Dopravca', link: 'https://www.generali.sk/pre-podnikatelov/dopravca/' },
          { label: 'Vozidlá', link: 'https://www.generali.sk/pre-podnikatelov/vozidla/' },
          { label: 'Lietadlá', link: 'https://www.generali.sk/pre-podnikatelov/lietadla/' },
          { label: 'Plavidlá', link: 'https://www.generali.sk/pre-podnikatelov/plavidla/' },
          { label: 'Poľnohospodárske poistenie', link: 'https://www.generali.sk/pre-podnikatelov/polnohospodarske-poistenie/' },
          { label: 'Skupinové poistenia', link: 'https://www.generali.sk/pre-podnikatelov/skupinove-poistenia/' },
          { label: 'Verejný prísľub', link: 'https://www.generali.sk/pre-podnikatelov/verejny-prislub/' },
        ],
      },
      {
        title: 'Ďalšie informácie',
        items: [
          { label: 'Pobočky', link: 'https://www.generali.sk/kontakt/pobocky/' },
          { label: 'Firemné centrá', link: 'https://www.generali.sk/kontakt/mapa-firemnych-centier/' },
          { label: 'Otázky novinárov', link: 'https://www.generali.sk/kontakt/otazky-novinarov/' },
          { label: 'Klientsky portál', link: 'https://www.generali.sk/kz/login.jsp' },
          { label: 'Nastavenie cookies', class: 'ot-sdk-show-settings', noSpecialProps: true },
        ],
      },

    ],
  }),
};
</script>

<style lang="scss" scoped>
.site-footer {
  background-color: $red-30;
  padding: $spacer-3 0;
  .invisible-title {
    display: none;
  }
  a, & {
    color: white;
  }
}

.footer-nav {
  margin-bottom: $spacer-1;
  .group-title {
    font-size: $font-size-300;
    margin-bottom: $spacer-1;
  }
  a {
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: $font-size-200;
  }
  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }
}

.footer-legal {
  font-size: $font-size-100;
  text-align: right;
  font-weight: 700;
  svg {
    color: white;
  }
  .group {
    opacity: 0.5;
  }
}

.logo {
  margin-bottom: $spacer-1;
}
</style>
