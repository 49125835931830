<template>
  <SectionText
    heading="Kariéra"
  >
    <div class="inner">
      <p><strong>Na zmenu nikdy nie je neskoro.</strong> Dvere pre šikovných a talentovaných ľudí máme stále otvorené. Pridaj sa k nám do tímu a vybuduj si u nás úspešnú kariéru.</p>
    </div>
    <div class="section-buttons-inline">
      <BaseButton
        variant="secondary"
        @click="openURL('https://generalislovenskadistribucia.sk/obchodnik-v-internej-sieti/')"
      >
        Obchodník v internej sieti
      </BaseButton>
      <BaseButton
        variant="secondary"
        @click="openURL('https://www.generali.sk/kariera/kariera-v-obchode/pozicia-a-kariera-manazera-obchodnej-skupiny-vos/')"
      >
        Manažér v internej sieti
      </BaseButton>
      <BaseButton
        variant="secondary"
        @click="openURL('https://generalislovenskadistribucia.sk/obchodnik-vo-firemnej-sieti/')"
      >
        Obchodník vo firemnej sieti
      </BaseButton>
    </div>
  </SectionText>
</template>

<script>
import BaseButton from '@gds/components/BaseButton';
import SectionText from '@/components/SectionText.vue';

export default {
  components: {
    BaseButton,
    SectionText,
  },
  methods: {
    openURL(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.inner {
  max-width: 580px;
}

.section-buttons-inline {
  button {
    margin-right: 1rem;
  }
}
</style>
