<template>
  <section class="section section-text">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <BaseHeading>
            {{ heading }}
          </BaseHeading>
        </div>
        <div class="content col-lg-8">
          <slot/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BaseHeading from '@/components/BaseHeading.vue';

export default {
  props: {
    heading: String,
  },
  components: {
    BaseHeading,
  },
};
</script>

<style lang="scss">
.section-text {
  .content {
    * {
      margin-bottom: $spacer-1;
    }
    @media screen and (min-width: 768px) {
      margin-top: $spacer-2;
    }
  }
}
</style>
