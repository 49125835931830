<template>
  <div
    role="tooltip"
    class="tooltip-window"
    :class="cssClasses"
  >
    <slot></slot>
    <svg
      class="arrow"
      :style="arrowOffsetStyle"
      width="18"
      height="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="currentColor" d="M9 0l9 9-9 9-9-9z" fill-rule="evenodd"/>
    </svg>
  </div>
</template>

<script>
import './TooltipWindow.scss';

export default {
  name: 'TooltipWindow',
  props: {
    placement: {
      // Placement based on origin (placement of the arrow is inverse to this)
      type: String,
      default: 'bottom',
    },
    arrowOffset: {
      type: Number,
    },
    isStatic: {
      type: Boolean,
    },
    padding: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
      default: 'dark',
      validator: (value) => ['dark', 'light'].includes(value),
    },
    accent: {
      type: String,
      default: 'none',
      validator: (value) => ['none', 'success', 'danger'].includes(value),
    },
  },
  computed: {
    cssClasses() {
      return [
        `-placement-${this.placement}`,
        `-theme-${this.variant}`,
        `-accent-${this.accent}`,
        this.isStatic ? '-static' : '-floating',
        this.padding ? '-padding' : '-no-padding',
      ];
    },
    arrowOffsetStyle() {
      if (this.isStatic && this.arrowOffset) {
        return (['top', 'bottom'].includes(this.placement))
          ? [{ left: `${this.arrowOffset}%` }]
          : [{ top: `${this.arrowOffset}%` }];
      }
      return null;
    },
  },
};
</script>
