<template>
  <div class="input-options">
    <label
      class="input-options-item"
      v-for="(option, i) in options"
      :key="i"
      :class="cssClasses(option)"
    >
      <input
        type="radio"
        class="radio"
        :name="name"
        :value="option.value"
        :checked="option.value === value"
        :disabled="option.disabled"
        @change="$emit('input', option.value); $emit('change', option.value)"
      >
      <FontAwesomeIcon
        v-if="option.icon"
        class="icon"
        :size="variantUsed === 'iconlarge' ? '3x' : '2x'"
        :icon="option.icon"
      />
      <div class="label">
        {{ option.label }}
      </div>
      <div v-if="option.subLabel" class="sublabel" v-html="option.subLabel">
      </div>
      <div v-if="option.tertiaryLabel" class="tertiarylabel" v-html="option.tertiaryLabel">
      </div>
      <BaseSpinner
        v-if="option.isLoading"
        :size="20"
        :variant="(option.value === value) ? 'white' : 'primary'"
        center
      />
    </label>
  </div>
</template>

<script>
import './InputOptions.scss';
import BaseSpinner from '@gds/components/BaseSpinner';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'InputOptions',
  components: {
    BaseSpinner,
    FontAwesomeIcon,
  },
  props: {
    value: {
      type: [String, Number, Boolean],
    },
    options: {
      type: Array,
    },
    variant: {
      type: String,
      validator: (value) => ['small', 'regular', 'large', 'icon', 'iconlarge'].includes(value),
    },
    name: {
      type: String,
      default: () => {
        const uid = Math.round(Math.random() * 1000);
        return `radio-uid-${uid}`;
      },
    },
  },
  computed: {
    variantUsed() {
      if (this.variant) return this.variant;
      if (this.options.some((option) => option.icon)) return 'icon';
      if (this.options.some((option) => option.subLabel)) return 'large';
      return 'regular';
    },
  },
  methods: {
    cssClasses(option) {
      return [
        `-variant-${this.variantUsed}`,
        (option.value === this.value) ? '-selected' : '',
        (option.disabled) ? '-disabled' : '',
      ];
    },
  },
};
</script>
