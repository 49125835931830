<template>
  <div id="app">
    <SiteHeader/>
    <router-view/>
    <SiteFooter/>
  </div>
</template>

<script>
import '@gds/styles/canvas.scss';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import '@/styles/main.scss';

import SiteHeader from '@/components/SiteHeader.vue';
import SiteFooter from '@/components/SiteFooter.vue';

export default {
  components: {
    SiteHeader,
    SiteFooter,
  },
};
</script>
